import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink

export function Navbar() {
  return (
    <nav className="main-header navbar navbar-expand-md navbar-dark">
      <div className="container">
        <NavLink to="/" className="navbar-brand">
          <img src="https://www.kingrango.com/images/kingrango.png" alt="King Rango Logo" className="brand-image elevation-3" />
          <span className="brand-text font-weight-light">King Rango Tool</span>
        </NavLink>
        <button
          className="navbar-toggler order-1"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse order-3" id="navbarCollapse">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink 
                to="/" 
                className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}
              >
                Home
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
